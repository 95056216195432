import momentTimeZone from 'moment-timezone'

export const checkEmptyString = {
  // inputに一度入力して削除した場合''になるのでnullににする。
  methods: {
    checkEmptyString(value) {
      if (value == '') {
        return null
      } else {
        return value
      }
    },
  },
}

export const convertTimezone = {
  methods: {
    convertTimezone(datetime, dateonly = true) {
      // utc->local_timeに変換する
      if (datetime) {
        let format = ''
        if (dateonly) {
          format = 'YYYY-MM-DD'
        } else {
          format = 'YYYY-MM-DD HH:mm:ss'
        }
        const convert_datetime = momentTimeZone
          .utc(datetime)
          .local()
          .format(format)
        return convert_datetime
      } else {
        return null
      }
    },
  },
}

export const getPxrSyncDate = {
  // PxR基盤との最終同期日時取得
  methods: {
    async getPxrSyncDate(pageId) {
      console.log('** get pxr sync date **')
      let ret = null
      await this.$axiosToken
        .get('/api/pxr_date/' + pageId)
        .then((response) => {
          console.log(response)
          ret = response.data.dateOfPxr
        })
        .catch((error) => {
          console.log(error)
        })
      return ret
    }
  }
}

export const openZoom = {
  // zoom画面を別windowで開く
  methods: {
    openZoom(imgPath1, imgPath2) {
      let routeData = this.$router.resolve({
        name: 'zoom-window',
        query: { path: [imgPath1, imgPath2] },
      })
      const w = screen.width * 0.4
      const h = screen.height * 0.6
      window.open(
        routeData.href,
        'ZoomWindow' + new Date().getTime(), //複数画面開くため、window名を毎回変える
        'width=' + w + ',height=' + h
      )
    },
  },
}
